exports.pakistanCityNames = [
  "Abbottabad",
  "Adezai",
  "Ahmadpur East",
  "Ahmed Nager Chatha",
  "Ali Khan Abad",
  "Alipur",
  "Arifwala",
  "Attock",
  "Awaran",
  "Badin",
  "Bahawalnagar",
  "Bahawalpur",
  "Banda Daud Shah",
  "Barkhan",
  "Basirpur",
  "Batkhela",
  "Bhakkar",
  "Bhalwal",
  "Bhimbar",
  "Bhirkan",
  "Bhiria City",
  "Burewala",
  "Chak",
  "Chakwal",
  "Chaman",
  "Charsadda",
  "Chichawatni",
  "Chiniot",
  "Chishtian",
  "Chitral",
  "Dadu",
  "Dainyor",
  "Dajkot",
  "Danda D neill",
  "Dara Adam Khel",
  "Dargai",
  "Darya Khan",
  "Daska",
  "Dera Bugti",
  "Dera Ghazi Khan",
  "Dera Ismail Khan",
  "Dhanot",
  "Dhaular",
  "Digri",
  "Dinga",
  "Dipalpur",
  "Diplo",
  "Dir",
  "Drosh",
  "Faisalabad",
  "Fateh Jang",
  "Ferozewala",
  "Fort Abbas",
  "Gadoon",
  "Gawadar",
  "Gharo",
  "Ghazi",
  "Ghotki",
  "Gojra",
  "Gujar Khan",
  "Gujranwala",
  "Gujrat",
  "Gwadar",
  "Gwadar",
  "Hafizabad",
  "Hala",
  "Hangu",
  "Haripur",
  "Harnai",
  "Hasilpur",
  "Haveli Lakha",
  "Hayatabad",
  "Hub",
  "Hyderabad",
  "Islamabad",
  "Jacobabad",
  "Jampur",
  "Jamshoro",
  "Jaranwala",
  "Jatoi",
  "Jauharabad",
  "Jhang",
  "Jhelum",
  "Jhudo",
  "Jiwani",
  "Jungshahi",
  "Kalabagh",
  "Kalat",
  "Kamalia",
  "Kamoke",
  "Kandhkot",
  "Kandiaro",
  "Karachi",
  "Karor Lal Esan",
  "Kashmore",
  "Kasur",
  "Khairpur",
  "Khanewal",
  "Khanpur",
  "Kharian",
  "Khuzdar",
  "Khyber",
  "Kohat",
  "Kohlu",
  "Kot Addu",
  "Kot Diji",
  "Kotri",
  "Kurram",
  "Lahore",
  "Lala Musa",
  "Lalamusa",
  "Larkana",
  "Lasbela",
  "Layyah",
  "Liaquat Pur",
  "Lodhran",
  "Mach",
  "Madyan",
  "Mailsi",
  "Makli",
  "Malakand",
  "Malakwal",
  "Mamoori",
  "Mandi Bahauddin",
  "Mandi Warburton",
  "Mangla",
  "Manguchar",
  "Mansehra",
  "Mardan",
  "Mastung",
  "Matiari",
  "Mehar",
  "Mian Channu",
  "Mianwali",
  "Mingaora",
  "Mirpur",
  "Mirpur Khas",
  "Mirpur Mathelo",
  "Mithi",
  "Moro",
  "Multan",
  "Muridke",
  "Murree",
  "Muzaffarabad",
  "Muzaffargarh",
  "Nankana Sahib",
  "Narowal",
  "Naseerabad",
  "Naudero",
  "Nawabshah",
  "North Waziristan",
  "Nowshera",
  "Okara",
  "Orakzai",
  "Pakpattan",
  "Panjgur",
  "Pasni City",
  "Pattoki",
  "Peshawar",
  "Phalia",
  "Pindi Bhattian",
  "Pir Mahal",
  "Pishin Valley",
  "Qambar",
  "Qasimabad",
  "Qila Saifullah",
  "Quaidabad",
  "Quetta",
  "Rahim Yar Khan",
  "Rajanpur",
  "Rahimyar Khan",
  "Ranipur",
  "Rawalakot",
  "Rawalpindi",
  "Renala Khurd",
  "Robat Thana",
  "Rohri",
  "Rohri",
  "Sadiqabad",
  "Sahiwal",
  "Saidu Sharif",
  "Saindak",
  "Sakrand",
  "Sambrial",
  "Sanghar",
  "Sangla Hill",
  "Sarai Alamgir",
  "Sargodha",
  "Shabqadar",
  "Shahdadkot",
  "Shahdadpur",
  "Shahpur Chakar",
  "Shangla",
  "Shikarpur",
  "Sialkot",
  "Sibi",
  "Sillanwali",
  "Sita Road",
  "Sukkur",
  "Swabi",
  "Swat",
  "Tando Adam Khan",
  "Tando Allahyar",
  "Tando Bago",
  "Tank",
  "Tarbela",
  "Tatlay Wali",
  "Taunsa Sharif",
  "Thal",
  "Thall",
  "Thatta",
  "Toba Tek Singh",
  "Tordher",
  "Torkham",
  "Turbat",
  "Umarkot",
  "Upper Dir",
  "Vehari",
  "Wah Cantonment",
  "Wana",
  "Warah",
  "Washuk",
  "Zhob",
  "Ziarat",
];
