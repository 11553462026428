import React from "react";
import "./Navbar.css";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { storeAuthFormData } from "../../features/authSlice";
import { Auth } from "../../Auth";
import { useState } from "react";

import MiniDrawer from "./SideBar";

const Navbar = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState("");
  const [permissions, setPermissions] = useState("");
  useEffect(() => {
    let obj = localStorage.getItem("userData");

    dispatch(storeAuthFormData(JSON.parse(obj)));

    getRole();
  }, []);

  const getRole = async () => {
    const result = await Auth();
    console.log(result.permission);
    setData(result.role);
    setPermissions(result.permission);
  };

  const userData = useSelector((state) => {
    return state.authFormData.authFormData.name;
  });
  const user = userData !== undefined ? userData : null;

  return (
    <>
      <MiniDrawer
        props={{
          user: userData,
          data: data,
          user: user,
          permissions: permissions,
        }}
      />
    </>
  );
};

export default Navbar;
