exports.phoneCode = [
  "992",
  "57",
  "856",
  "297",
  "63",
  "62",
  "942",
  "928",
  "829",
  "453",
  "5828",
  "832",
  "939",
  "825",
  "543",
  "943",
  "966",
  "25",
  "835",
  "64",
  "945",
  "944",
  "41",
  "723",
  "55",
  "53",
  "86",
  "547",
  "925",
  "995",
  "22",
  "51",
  "722",
  "838",
  "837",
  "47",
  "544",
  "826",
  "844",
  "21",
  "927",
  "49",
  "852",
  "243",
  "65",
  "847",
  "454",
  "848",
  "823",
  "922",
  "998",
  "574",
  "926",
  "42",
  "969",
  "74",
  "853",
  "606",
  "608",
  "824",
  "932",
  "546",
  "997",
  "937",
  "843",
  "459",
  "233",
  "924",
  "61",
  "828",
  "58",
  "66",
  "542",
  "242",
  "244",
  "923",
  "44",
  "457",
  "855",
  "91",
  "81",
  "68",
  "604",
  "40",
  "235",
  "48",
  "996",
  "56",
  "726",
  "52",
  "833",
  "965",
  "71",
  "938",
  "946",
  "963",
  "232",
  "298",
  "46",
  "238",
  "67",
  "822",
];
